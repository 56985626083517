<template>
  <div class="pt-5">
    <!--body content start-->

    <div class="page-content">
      <!--service details start-->

      <section style="background-image: url(/images/pattern/01.png)">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="row align-items-center" data-bg-img="/images/pattern/01.png">
                <div class="col-md-7">
                  <div class="case-images">
                    <img class="img-fluid" src="/default/02.png" alt="" />
                  </div>
                </div>
                <div class="col-md-5">
                  <h1 class="title">Messaging</h1>
                  <p>Send effective text communication to your customers when they need it. Our easy-to-use and reliable
                    SMS APIs provide a wide variety of ways for you to add text messaging to your mobile and web
                    applications.</p>
                  <p><router-link :to="{ name: 'pricing.sms' }"
                      class="btn btn-white bg-secondary text-white btn-rounded mt-5"><i class="fa fa-money mr-2"></i>
                      View Pricing</router-link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Importance of bulk SMS to a business -->

      <section class="text-center o-hidden">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-10 ml-auto mr-auto">
              <div class="section-title">
                <h2 class="title">Importance of bulk SMS to business</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <div class="work-process">
                <div class="work-process-inner text-align-left">
                  <div class="text-align-left-sms">
                    <h4>1. Impressive Conversion Rate</h4>
                    <p>At around 6%, response rates for email are notoriously low. However, a response rate of 45% for
                      SMS
                      is not unusual and may often be even higher. Most people respond to a text within 90 seconds
                      compared to 90 minutes for email. Whatever the objective of your marketing campaign, promotions,
                      surveys or sign ups, you will receive a higher response rate than any other marketing strategy
                      with
                      SMS.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 md-mt-5">
              <div class="work-process">
                <div class="work-process-inner text-align-left">
                  <div class="text-align-left-sms">
                    <h4>2. High Open Rate</h4>
                    <p>The open rate of SMS messages has been reported to be as high as 99% compared to just 20% for
                      emails. Of course, not everyone who reads your text will respond. But it stands to reason there is
                      a
                      higher chance of someone responding to your marketing if they have seen your message than if they
                      have not. People open nearly every text message they receive; many choose to delete an email
                      without
                      opening it.</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 md-mt-5">
              <div class="work-process">
                <div class="work-process-inner text-align-left">
                  <div class="text-align-left-sms">
                    <h4>3. High Open Rate</h4>
                    <p>The open rate of SMS messages has been reported to be as high as 99% compared to just 20% for
                      emails. Of course, not everyone who reads your text will respond. But it stands to reason there is
                      a
                      higher chance of someone responding to your marketing if they have seen your message than if they
                      have not. People open nearly every message they receive , many choose to delete an email without
                      opening it.</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 md-mt-5">
              <div class="work-process">
                <div class="work-process-inner text-align-left">
                  <div class="text-align-left-sms">
                    <h4>4. Customizable Campaigns</h4>
                    <p>Bulk SMS marketing allows you to target your whole database of contacts, a sub-group or even an
                      individual. Personalizing your marketing campaign is key to its success. You'll want to send a
                      different text to prospects than to existing customers, for example. It's quick and easy to set up
                      templates and customize an SMS each time you send one out. Consumers are much more likely to
                      respond
                      to a message that appeals specifically to them than to a generic or irrelevant message.</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 md-mt-5">
              <div class="work-process">
                <div class="work-process-inner text-align-left">
                  <div class="text-align-left-sms">
                    <h4>5. Cost Effective</h4>
                    <p>Compared to the cost of other marketing methods, such as TV, radio or print, SMS bulk marketing
                      is
                      very affordable. Messaging is low cost and delivers a higher Return On Investment (ROI) than many
                      other marketing strategies, marketing it a cost-effective and sustainable option.</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 md-mt-5">
              <div class="work-process">
                <div class="work-process-inner text-align-left">
                  <div class="text-align-left-sms">
                    <h4>6. Minimal Effort Required from Recipients</h4>
                    <p>There is a 160 character length for SMS, forcing marketers to deliver their message in a concise,
                      easy to read way. Brief messages make it much easier for recipients to understand the crux of your
                      SMS campaign.</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 md-mt-5">
              <div class="work-process">
                <div class="work-process-inner text-align-left">
                  <div class="text-align-left-sms">
                    <h4>7. Few Barriers to Delivery</h4>
                    <p>Marketing emails often never reach their intended recipient due to spam filters. This is not the
                      case with SMS. There are no barriers to delivery, so SMS messaging is highly reliable.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <!--service details end-->

      <section class="text-center o-hidden">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-10 ml-auto mr-auto">
              <div class="section-title">
                <h6>How It Works</h6>
                <h2 class="title">Three Easy Steps To Get Started.</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <div class="work-process">
                <div class="work-process-inner">
                  <div class="work-img">
                    <img class="img-center" src="/images/how-it-work/01.png" alt="" />
                    <span class="step-num">01</span>
                  </div>
                  <h4>Create account and login</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 md-mt-5">
              <div class="work-process">
                <div class="work-process-inner">
                  <div class="work-img">
                    <img class="img-center" src="/images/how-it-work/02.png" alt="" />
                    <span class="step-num">02</span>
                  </div>
                  <h4>Create app and pick a service</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 md-mt-5">
              <div class="work-process">
                <div class="work-process-inner">
                  <div class="work-img">
                    <img class="img-center" src="/images/how-it-work/03.png" alt="" />
                    <span class="step-num">03</span>
                  </div>
                  <h4>Top up and enjoy the services</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!--body content end-->
  </div>
</template>

<script>
export default {};
</script>
